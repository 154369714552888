import styled from 'styled-components/native';
import { verticalScale } from 'react-native-size-matters';

import styles from '../../utils/styles';
import { isBrower } from '../../utils/functions';
import { Dimensions } from 'react-native';

export const Container = styled.View`
    height: ${Dimensions.get('window').height}px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
`;

export const WebFormContainer = styled.View`
    width: ${verticalScale(200)}px;
    height: ${verticalScale(200)}px;
    justify-content: center;
    align-items: center;
    background-color: ${styles.formModalColor};
`;

export const FormContainer = styled.View`
    width: 100%;
    height: 80%;
    justify-content: ${isBrower() ? 'flex-start' : 'center'};
    align-items: center;
`;


export const FormTitleContainer = styled.View`
    width: 100%;
    display: flex;
    ${isBrower() ? 'flex: .2;' : 'height: 60px;'}
    justify-content: center;
    align-items: center;
`;

export const FormTitle = styled.Text`
    font-family: roboto_bold ;
    font-size: ${verticalScale(isBrower() ? 10 : 18)}px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: ${styles.formTitle};
`;

export const FormInputsContainer = styled.View`
    width: 100%;
    display: flex;
    flex: .6;
    justify-content: center;
    align-items: center;
`;

export const FormButtonContainer = styled.View`
    width: 100%;
    display: flex;
    flex: .2;
    justify-content: ${isBrower() ? 'flex-start' : 'center'};
    align-items: ${isBrower() ? 'flex-end' : 'center'};
    padding-right: ${isBrower() ? '50px' : '0'}
`;

export const FormSubmitButton = styled.TouchableOpacity`
    width: ${isBrower() ? '200px' : '80%'};
    height: 60px;
    justify-content: center;
    align-items: center;
    background-color: ${styles.formButtonColor};
`;

export const FormSubmitButtonText = styled.Text`
    font-family: roboto_bold ;
    font-size: ${verticalScale(isBrower() ? 8 : 18)}px;
    color: #FFFFFF;
`;

export const ButtonLoader = styled.ActivityIndicator.attrs({
    color: '#fff',
})``;

export const PageLoader = styled.ActivityIndicator.attrs({
    color: styles.pageLoader,
    size: 100,
})``;