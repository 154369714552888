import React, { useState } from 'react';
import { TextInputProps } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';

import {
  Container,
  Label,
  Error,
  Input,
  Icon,
  InputContainer
} from './styles';

interface Props extends TextInputProps {
  label: string,
  error?: string,
  placeholder?: string,
  value: any,
  onChange: any,
  isSecureText?: boolean,
}

const InputWithLabel: React.FC<Props> = ({
  label,
  error = undefined,
  placeholder,
  value,
  onChange,
  isSecureText = false,
}) => {
  const [seePass, setSeePass] = useState(isSecureText);
  return (
    <Container>
      <Label>{label}</Label>
      <InputContainer>
        {
          isSecureText &&
          <TouchableWithoutFeedback
            onPressIn={() => setSeePass(!seePass)}
            style={
              {
                marginLeft: 15,
              }
            }
          >
            <Icon
              name={seePass ? 'eye' : 'eye-slash'}
              size={24}
            />
          </TouchableWithoutFeedback>
        }

        <Input
          placeholder={placeholder}
          onChangeText={onChange}
          value={value}
          secureTextEntry={seePass}
        />
      </InputContainer>

      {error !== undefined && <Error>{error}</Error>}
    </Container>
  );
}

export default InputWithLabel;