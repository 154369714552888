import { DeviceTypes, Devicestate, FetchAction } from './types';
import { Reducer } from 'redux';

const initialState: Devicestate = {
  ID: "",
  IMEI: "",
  Group_ID: "",
  Name: "",
  DeviceType: "",
  TimeZone: "",
  DateTimeCreated: "",
  DateTimeLastModified: "",
};

const reducer: Reducer<Devicestate, FetchAction> = (state = initialState, action: FetchAction) => {

  switch (action.type) {
      
    case DeviceTypes.POST_DEVICE_REQUEST:
      return { 
        ...state, 
        ID: action.payload.ID,
        IMEI: action.payload.IMEI,
        Group_ID: action.payload.Group_ID,
        Name: action.payload.Name,
        DeviceType: action.payload.DeviceType,
        TimeZone: action.payload.TimeZone,
        DateTimeCreated: action.payload.DateTimeCreated,
        DateTimeLastModified: action.payload.DateTimeLastModified,
      };

    default:
      return state;
  }

};

export default reducer;