import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as Linking from 'expo-linking';

import pages from "../pages";

const Stack = createStackNavigator();

const prefix = Linking.createURL('/');

export function Routes() {

    const linking = {
        prefixes: [prefix],
    };

    return (
        <NavigationContainer linking={linking}>
            <Stack.Navigator
                initialRouteName="/"
                screenOptions={{
                    headerShown: false,
                }}>

                <Stack.Screen name="login" component={pages.Home} />
                <Stack.Screen name="search" component={pages.Search} />
                <Stack.Screen name="dashboard" component={pages.Dashboard} />

            </Stack.Navigator>
        </NavigationContainer>
    );


}