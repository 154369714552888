export enum AuthTypes {
  POST_AUTH_REQUEST = '@auth/POST_AUTH_REQUEST',
  POST_AUTH_SUCCESS = '@auth/POST_AUTH_SUCCESS',
  POST_AUTH_ERROR = '@auth/POST_AUTH_ERROR',
  POST_AUTH_LOGOUT_REQUEST = '@auth/POST_AUTH_LOGOUT_REQUEST',
  POST_AUTH_MY_USER = '@auth/POST_AUTH_MY_USER',
}

export interface Auth {
  access_token: string,
  error: string,
}

export interface Authstate {
  readonly access_token: string,
  readonly error: string,
}

export interface FetchAction {
  type: string;
  payload: any;
}