import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import device_list from './device_list/sagas';
import device_location from './device_location/sagas';

export default function* root() {
  yield all([
    auth(),
    device_list(),
    device_location(),
  ]);
}