import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { FetchAction, DeviceLocationTypes } from './types';
import { postDeviceLocationSuccess, postDeviceLocationError } from './actions';
import auth from '../../../service/authAPI';

const baseURL = "https://tramigocloud.com/api";

function* device_location(action: FetchAction) {

  auth.get(baseURL+'/reports/last_location/'+action.payload.id).then((res) => {
    action.payload.callback(res.data.main_reports[0])
  }).catch((err) => {
    action.payload.callback(false)
  })
}

export default function* root() {
  yield takeLatest(DeviceLocationTypes.POST_DEVICE_LOCATION_REQUEST, device_location);
}