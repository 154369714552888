import { DeviceLocationTypes, DeviceLocationstate, FetchAction } from './types';
import { Reducer } from 'redux';

const initialState: DeviceLocationstate = {
  ID: "",
  IMEI: "",
  Group_ID: "",
  Name: "",
  DeviceType: "",
  OtherSettings: "",
  TimeZone: "",
  DateTimeCreated: "",
  DateTimeLastModified: "",
};

const reducer: Reducer<DeviceLocationstate, FetchAction> = (state = initialState, action: FetchAction) => {

  switch (action.type) {
    case DeviceLocationTypes.POST_DEVICE_LOCATION_SUCCESS:
      return { 
        ...state, 
        ID: action.payload.ID,
        IMEI: action.payload.IMEI,
        Group_ID: action.payload.Group_ID,
        Name: action.payload.Name,
        DeviceType: action.payload.DeviceType,
        OtherSettings: action.payload.OtherSettings,
        TimeZone: action.payload.TimeZone,
        DateTimeCreated: action.payload.DateTimeCreated,
        DateTimeLastModified: action.payload.DateTimeLastModified,
      };
    
    case DeviceLocationTypes.POST_DEVICE_LOCATION_ERROR:
      return { 
        ...state, 
        ID: "",
        IMEI: "",
        Group_ID: "",
        Name: "",
        DeviceType: "",
        OtherSettings: "",
        TimeZone: "",
        DateTimeCreated: "",
        DateTimeLastModified: "",
      };

    default:
      return state;
  }

};

export default reducer;