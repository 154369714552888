import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import InputWithLabel from '../../components/InputWithLabel';

import LogoHeader from '../../components/LogoHeader';
import { postAuthLogoutRequest, postAuthMyUser } from '../../store/ducks/auth/actions';
import { postDeviceRequest } from '../../store/ducks/device/actions'
import { postDeviceListRequest } from '../../store/ducks/device_list/actions';
import { isBrower } from '../../utils/functions';

import {
  Container,
  WebFormContainer,
  FormContainer,
  FormTitleContainer,
  FormTitle,
  FormInputsContainer,
  FormButtonContainer,
  FormSubmitButton,
  FormSubmitButtonText,
  Loading,
  PageLoader,
} from './styles';
import BG from '../../components/BG';

const Search: React.FC = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [devices, setDevices] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const access_token = useSelector((state: any) => state.auth.access_token);
  const language = useSelector((state: any) => state.language.search);
  const errorsMessages = useSelector((state: any) => state.language.errors);

  useEffect(() => {
    dispatch(postDeviceListRequest((v: any) => {
      if (v && v.length >= 0) {
        setDevices(v);
        setPageLoading(false);
      } else {
        setPageLoading(false);
        setError(errorsMessages.NO_VEHICLE_FOUND);
      }
    }));
  }, []);

  useEffect(() => {
    if (access_token && access_token !== "") {
      dispatch(postAuthMyUser((res: any) => {
        if (!res) {
          dispatch(postAuthLogoutRequest());
          navigation.navigate("login");
        }
      }
      ));
    }else{
      dispatch(postAuthLogoutRequest());
      navigation.navigate("login");
    }
    setLoading(false);
    setError('');
  }, [access_token]);

  function handleRequest() {
    setLoading(true);
    if (value.length > 0) {
      if (devices && devices.length > 0) {
        const device = devices.find(e => e.ID === parseInt(value) || e.Name.includes(value));
        if (device !== undefined) {
          dispatch(postDeviceRequest(device));
          navigation.navigate("dashboard");
          setLoading(false);
        } else {
          setLoading(false);
          setError(errorsMessages.NO_VEHICLE_FOUND);
        }
      } else {
        setLoading(false);
        setError(errorsMessages.NO_DEVICE_DATA_FOUND);
      }
    } else {
      setLoading(false);
      setError(errorsMessages.INPUT_EMPTY);
    }

  }

  const getFormLayout = () => {

    return (
      <>

        <FormTitleContainer>
          <FormTitle>
            {language.title}
          </FormTitle>
        </FormTitleContainer>

        <FormInputsContainer>
          <InputWithLabel
            label={language.inputLabel}
            placeholder={language.inputPlaceholder}
            onChange={(e: any) => setValue(e)}
            value={value}
            error={error}
            accessible={!loading}
          />
        </FormInputsContainer>

        <FormButtonContainer>
          <FormSubmitButton onPressIn={handleRequest}>
            {loading ?
              <Loading />
              :
              <FormSubmitButtonText>
                {language.button}
              </FormSubmitButtonText>
            }
          </FormSubmitButton>
        </FormButtonContainer>

      </>
    );
  }
  return (
    <>
      <BG />
      <Container>

        <LogoHeader />
        {pageLoading ?
          <PageLoader />
          :
          <>
            {
              isBrower() ?
                <WebFormContainer>
                  {getFormLayout()}
                </WebFormContainer>
                :
                <FormContainer>
                  {getFormLayout()}
                </FormContainer>
            }
          </>
        }

      </Container>
    </>
  );
}

export default Search;