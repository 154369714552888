import 'react-native-gesture-handler';
import React, { useState, useEffect } from 'react';
import { StatusBar } from 'react-native';
import { AppearanceProvider } from 'react-native-appearance';
import { Provider } from 'react-redux';
import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';

import { store } from './store';
import { Routes } from './routes';

const src: React.FC = () => {

    // Import fonts
    const [isFontLoad, setIsFontLoad] = useState(false);

    // Load a font from a static resource
    async function loadFonts() {
        await Font.loadAsync({
            roboto_regular: require('../assets/fonts/Roboto-Regular.ttf'),
            roboto_bold: require('../assets/fonts/Roboto-Bold.ttf'),
        });
        setIsFontLoad(true);
    }

    useEffect(() => {

        loadFonts();

    }, []);

    if (!isFontLoad) {
        return <AppLoading />
    }

    return (
        <>
            <AppearanceProvider>
                <StatusBar barStyle="dark-content" />
                <Provider store={store}>
                    <Routes />
                </Provider>
            </AppearanceProvider>
        </>
    );
}

export default src;