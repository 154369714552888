export enum DeviceLocationTypes {
  POST_DEVICE_LOCATION_REQUEST = '@device_location/POST_DEVICE_LOCATION_REQUEST',
  POST_DEVICE_LOCATION_SUCCESS = '@device_location/POST_DEVICE_LOCATION_SUCCESS',
  POST_DEVICE_LOCATION_ERROR = '@device_location/POST_DEVICE_LOCATION_ERROR',
}

export interface DeviceLocation {
  Latitude: string,
  Longitude: string,
}

export interface DeviceLocationstate {
  readonly Latitude: string,
  readonly Longitude: string,
}

export interface FetchAction {
  type: string;
  payload: any;
}