export enum DeviceListTypes {
  POST_DEVICE_REQUEST = '@device/POST_DEVICE_REQUEST',
  POST_DEVICE_LIST_REQUEST = '@device_list/POST_DEVICE_LIST_REQUEST',
  POST_DEVICE_LIST_SUCCESS = '@device_list/POST_DEVICE_LIST_SUCCESS',
  POST_DEVICE_LIST_ERROR = '@device_list/POST_DEVICE_LIST_ERROR',
}

export interface DeviceList {
  ID: string,
  IMEI: string,
  Group_ID: string,
  Name: string,
  DeviceType: string,
  OtherSettings: string,
  TimeZone: string,
  DateTimeCreated: string,
  DateTimeLastModified: string,
}

export interface DeviceListstate {
  readonly ID: string,
  readonly IMEI: string,
  readonly Group_ID: string,
  readonly Name: string,
  readonly DeviceType: string,
  readonly OtherSettings: string,
  readonly TimeZone: string,
  readonly DateTimeCreated: string,
  readonly DateTimeLastModified: string,
}

export interface Device {
  ID: string,
  IMEI: string,
  Group_ID: string,
  Name: string,
  DeviceType: string,
  TimeZone: string,
  DateTimeCreated: string,
  DateTimeLastModified: string,
}

export interface Devicestate {
  readonly ID: string,
  readonly IMEI: string,
  readonly Group_ID: string,
  readonly Name: string,
  readonly DeviceType: string,
  readonly TimeZone: string,
  readonly DateTimeCreated: string,
  readonly DateTimeLastModified: string,
}

export interface FetchAction {
  type: string;
  payload: any;
}