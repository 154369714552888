import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { FetchAction, DeviceListTypes } from './types';
import { postDeviceListSuccess, postDeviceListError } from './actions';

const config = require('../../../config').default;

const baseURL = "https://tramigocloud.com/api";

/* import auth from '../../../service/authAPI'; */

function* device_list(action: FetchAction) {

  axios.post(baseURL+'/login', {
    username: config["tramingoUser"],
    password: config["tramingoPass"]
  })
  .then(function (response) {
    const instance = axios.create({
      baseURL: baseURL,
      headers: {'Authorization': 'Bearer '+response.data.access_token}
    });
  
    instance.get(baseURL+'/devices').then((res) => {
      action.payload(res.data)
    }).catch((err) => {
      action.payload(false)
    })
  })
  .catch(function (err) {
    action.payload(false)
  });
}

export default function* root() {
  yield takeLatest(DeviceListTypes.POST_DEVICE_LIST_REQUEST, device_list);
}