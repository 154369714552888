import { put, takeLatest } from 'redux-saga/effects';
import { FetchAction, AuthTypes } from './types';
import { postAuthSuccess, postAuthError } from './actions';

import auth from '../../../service/authAPI';

function* login(action: FetchAction) {
  let response = null;
  let error = null;

  yield auth.post('/login', action.payload).then((res) => {
    response = res.data; 
  }).catch((err) => {
    error = err.response.data.message;
  })

  if (response) {
    yield put(postAuthSuccess(response))
  }else if (error) {
    yield put(postAuthError(error))
  }
}

function* my_user(action: FetchAction) {
  yield auth.get('/my_user').then((res) => {
    action.payload(res.data);
  }).catch((err) => {
    action.payload(false);
  });
}

export default function* root() {
  yield takeLatest(AuthTypes.POST_AUTH_REQUEST, login);
  yield takeLatest(AuthTypes.POST_AUTH_MY_USER, my_user);
}