import React, { useState } from 'react';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import InputSearch from '../InputSearch';

import { postAuthLogoutRequest } from '../../store/ducks/auth/actions';
import { postDeviceRequest } from '../../store/ducks/device/actions';
import { postDeviceListRequest } from '../../store/ducks/device_list/actions';

import {
  Container,
  Logo,
  Label,
  SearchButton,
  SearchButtonText,
} from './styles';
import { isBrower } from '../../utils/functions';

const DashHeader: React.FC = () => {

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');


  const language = useSelector((state: any) => state.language.dashboard);
  const errorsMessages = useSelector((state: any) => state.language.errors);

  function handleLogout() {
    dispatch(postAuthLogoutRequest());
    navigation.navigate("login");
  }

  function searchNewDevice() {
    setLoading(true);
    if (value.length > 0) {
      dispatch(postDeviceListRequest((v: any) => {
        if (v && v.length > 0) {
          const device = v.find(e => e.ID === parseInt(value) || e.Name.includes(value));
          setLoading(false);
          if (device !== undefined) {
            setError('');
            dispatch(postDeviceRequest(device));
          } else {
            alert(errorsMessages.NO_VEHICLE_FOUND);
          }
        } else {
          setLoading(false);
          alert(errorsMessages.NO_DEVICE_DATA_FOUND);
        }
      }));
    } else {
      setLoading(false);
      alert(errorsMessages.INPUT_EMPTY);
    }
  }

  function handleNewSearch() {
    navigation.navigate('search');
  }

  return (
    <Container>
      <Logo source={isBrower() ? require('../../../assets/logos/icon_wide_transparent.png') : require('../../../assets/logos/adaptive_icon.png')} />
      {/* <InputSearch
        label={language.labelSearch}
        placeholder={language.placeholderSearch}
        onChange={(e: any) => setValue(e)}
        value={value}
        loading={loading}
        error={error}
        onPress={searchNewDevice}
      /> */}

      <SearchButton onPressIn={handleNewSearch}>
        <SearchButtonText>
          {language.buttonSeach}
        </SearchButtonText>
      </SearchButton>

      <TouchableWithoutFeedback
        onPressIn={handleLogout}
        style={{
          marginRight: 30,
        }}
      >
        <Label>{language.logoutButton}</Label>
      </TouchableWithoutFeedback>
    </Container>
  );
}

export default DashHeader;