export default {
    pt: {
        login: {
            title: 'Login',
            button: 'Entrar',
            inputUserLabel: 'Usuário:',
            inputUserPlaceholder: 'exemplo@email.com',
            inputPassLabel: 'Senha',
            inputPassPlaceholder: 'Vossa Senha',
        },
        search: {
            title: 'Localize a Viatura',
            button: 'Pesquisar',
            inputLabel: 'Nome ou Chapa de Matrícula',
            inputPlaceholder: 'Nome da viatura ou Chapa de Matrícula',
        },
        dashboard: {
            title: 'Mais Detalhes:',
            logoutButton: 'Sair',
            labelSearch: 'Pesquisar',
            placeholderSearch: 'Nome do veículo ou Chapa de Matrícula',
            buttonSeach: 'Nova Pesquisa',
            infoData: {
                title: 'Dados da Viatura:',
                subtitle: 'Viatura:',
                fieldOne: 'Id:',
                fieldTwo: 'Nome:',
            },
            infoLocation: {
                title: 'Localização:',
                subtitle: 'Posição Atual:',
                fieldOne: 'Latitude',
                fieldTwo: 'Longitude',
            },
            infoScript: {
                title: 'Previsão:',
                subtitle: 'Chegada Estimada:',
                fieldOne: 'Chega às',
                fieldTwo: 'Distancia',
            },
        },
        errors: {
            NO_VEHICLE_FOUND: 'Não encontramos essa viatura.',
            NO_DEVICE_DATA_FOUND: 'Não encontramos viaturas.',
            INPUT_EMPTY: 'O campo não pode estar vazio.',
        },
    },
    eua: {
        login: {
            title: 'Login',
            button: 'Log in',
            inputUserLabel: 'User:',
            inputUserPlaceholder: 'example@email.com',
            inputPassLabel: 'Password',
            inputPassPlaceholder: 'Your Password',
        },
        search: {
            title: 'Locate the Vehicle:',
            button: 'Search',
            inputLabel: 'Name or Id ',
            inputPlaceholder: 'Vehicle name or unique identifier',
        },
        dashboard: {
            title: 'More Details:',
            logoutButton: 'Logout',
            labelSearch: 'Search',
            placeholderSearch: 'Vehicle name or unique identifier',
            buttonSeach: 'New Search',
            infoData: {
                title: 'Vehicle Data:',
                subtitle: 'Vehicle:',
                fieldOne: 'Id:',
                fieldTwo: 'Name:',
            },
            infoLocation: {
                title: 'Localization:',
                subtitle: 'Current Position:',
                fieldOne: 'Latitude',
                fieldTwo: 'Longitude',
            },
            infoScript: {
                title: 'Forecast:',
                subtitle: 'Estimated Arrival:',
                fieldOne: 'Arrives at',
                fieldTwo: 'Distance',
            },
        },
        errors: {
            NO_VEHICLE_FOUND: 'We did not find this vehicle',
            NO_DEVICE_DATA_FOUND: 'We did not find vehicles',
            INPUT_EMPTY: 'The field cannot be empty.',
        },
    },
    esp: {
        login: {
            title: 'Login',
            button: 'Iniciar Sesión',
            inputUserLabel: 'Nombre de Usuario:',
            inputUserPlaceholder: 'ejemplo@email.com',
            inputPassLabel: 'Contraseña',
            inputPassPlaceholder: 'Su Contraseña',
        },
        search: {
            title: 'Localizar Vehículo',
            button: 'Buscar',
            inputLabel: 'Nombre o Identificación',
            inputPlaceholder: 'Nombre del vehículo o Identificador ùnico',
        },
        dashboard: {
            title: 'Más Detalles:',
            logoutButton: 'Salir',
            labelSearch: 'Buscar',
            placeholderSearch: 'Nombre del vehículo o Identificador ùnico',
            buttonSeach: 'Nueva Búsqueda',
            infoData: {
                title: 'Datos del Vehículo:',
                subtitle: 'Vehículo:',
                fieldOne: 'Id:',
                fieldTwo: 'Nombre:',
            },
            infoLocation: {
                title: 'Localización:',
                subtitle: 'Posición Actual:',
                fieldOne: 'Latitude',
                fieldTwo: 'Longitude',
            },
            infoScript: {
                title: 'Pronóstico:',
                subtitle: 'Llegada Estimada:',
                fieldOne: 'Llega a las',
                fieldTwo: 'Distancia',
            },
        },
        errors: {
            NO_VEHICLE_FOUND: 'No encontramos este vehículo',
            NO_DEVICE_DATA_FOUND: 'no encontramos vehículos',
            INPUT_EMPTY: 'El campo no puede estar vacío',
        },
    },
    cn: {
        login: {
            title: '登錄:',
            button: '使用權',
            inputUserLabel: '用戶',
            inputUserPlaceholder: '例子@email.com',
            inputPassLabel: '密碼',
            inputPassPlaceholder: '你的密碼',
        },
        search: {
            title: '找車:',
            button: '搜索',
            inputLabel: '姓名或身份證',
            inputPlaceholder: '車輛名稱或唯一標識符',
        },
        dashboard: {
            title: '更多細節:',
            logoutButton: '出去',
            labelSearch: '搜索',
            placeholderSearch: '車輛名稱或唯一標識符',
            buttonSeach: '新搜索',
            infoData: {
                title: '車輛數據:',
                subtitle: '車輛:',
                fieldOne: 'Id:',
                fieldTwo: '名稱:',
            },
            infoLocation: {
                title: '本土化:',
                subtitle: '當前位置:',
                fieldOne: '緯度',
                fieldTwo: '經度',
            },
            infoScript: {
                title: '預報:',
                subtitle: '預計抵達時間:',
                fieldOne: '將到達',
                fieldTwo: '距離',
            },
        },
        errors: {
            NO_VEHICLE_FOUND: '未找到車輛',
            NO_DEVICE_DATA_FOUND: '未找到車輛',
            INPUT_EMPTY: '該字段不能為空',
        },
    },
}