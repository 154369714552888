import React from 'react';

import { Image } from './styles';

const BG: React.FC = () => {

  return (
    <Image source={require('../../../assets/images/pinbg.png')} />
  );
}

export default BG;