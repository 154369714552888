import React from 'react';
import { Text } from 'react-native';

import { Container, Logo } from './styles';

const LogoHeader: React.FC = () => {

  return (
    <Container>
      <Logo source={require('../../../assets/logos/wide-logo.png')} />
    </Container>
  );
}

export default LogoHeader;