import Home from "./Home";
import Search from "./Search";
import Dashboard from "./Dashboard";


const index = {
    Home,
    Search,
    Dashboard,
}

export default index;
