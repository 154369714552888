import styled from 'styled-components/native';
import { scale, verticalScale } from 'react-native-size-matters';

import styles from '../../utils/styles';
import { isBrower } from '../../utils/functions';
import { Dimensions } from 'react-native';

export const Container = styled.View`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    background-color: #FFFFFF;
`;

export const ContainerMap = styled.View`
    flex-direction: ${isBrower() ? "row" : "column"};
    display: flex;
    height: ${Dimensions.get('window').height-scale(isBrower() ? 35 : 60)}px;
    width: 100%;
    justify-content: ${isBrower() ? "center" : "flex-start"};
    align-items: center;
    background-color: #FFFFFF;
`;

export const ContainerMapSideBar = styled.View`
    width: ${isBrower() ? "20%" : "100%"};
    height: ${isBrower() ? "100%" : "150px"};
    justify-content: flex-start;
    align-items: center;
    background-color: #FFFFFF;
`;

export const SideBarTitle = styled.Text`
    font-family: roboto_bold;
    font-size: ${verticalScale(isBrower() ? 7 : 15)}px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: ${styles.sideBarTitle};
    margin-bottom: 30px;
`;

export const ContainerStatusCard = styled.View`
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: #F6F6F6;
    padding: 5px;
    margin-bottom: 50px;
`;

export const StatusCardRow = styled.View`
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
`;

export const StatusCardColumn = styled.View`
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
`;

export const StatusCardTitle = styled.Text`
    font-family: roboto_bold;
    font-size: ${verticalScale(isBrower() ? 6 : 13)}px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: ${styles.statusCardTitle};
    margin-bottom: 10px;
`;

export const StatusCardSubTitle = styled.Text`
    font-family: roboto_bold;
    font-size: ${verticalScale(isBrower() ? 6 : 13)}px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: ${styles.statusCardSubTitle};
    margin-bottom: 5px;
`;

export const StatusCardLabel = styled.Text`
    font-family: roboto_regular;
    font-size: ${verticalScale(isBrower() ? 6 : 13)}px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: ${styles.statusCardLabel};
`;

export const Loading = styled.ActivityIndicator.attrs({
    color: '#fff',
})``;

export const PageLoader = styled.ActivityIndicator.attrs({
    color: styles.pageLoader,
    size: 100,
})`
    margin-bottom: ${isBrower() ? 25 : 100}%;
`;