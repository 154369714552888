import styled from 'styled-components/native'
import { scale, verticalScale } from 'react-native-size-matters'

import { isBrower } from '../../utils/functions';
import styles from '../../utils/styles';

export const Container = styled.View`
    position: absolute;
    flex-direction: row;
    width: 100%;
    height: ${scale(isBrower() ? 35 : 60)}px;
    top: 0;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
`;

export const Logo = styled.Image`
    width: ${scale(isBrower() ? 60 : 30)}px;
    height: 40px;
    resize-mode: contain;
    margin-left: ${isBrower() ? 30 : 10}px; 
    margin-right: ${isBrower() ? 0 : 10}px; 
`;

export const Label = styled.Text`
    font-family: roboto_regular ;
    font-size: ${scale(isBrower() ? 7 : 15)}px;
    line-height: 26px;
    color: ${styles.inputLabelText};
    margin-horizontal: ${scale(10)}px;
`;

export const SearchButton = styled.TouchableOpacity`
    width: ${isBrower() ? '200px' : '40%'};
    height: 60px;
    justify-content: center;
    align-items: center;
    background-color: ${styles.formButtonColor};
`;

export const SearchButtonText = styled.Text`
    font-family: roboto_bold ;
    font-size: ${verticalScale(isBrower() ? 7 : 15)}px;
    color: #FFFFFF;
`;