import styled from 'styled-components/native'
import { verticalScale } from 'react-native-size-matters'
import { FontAwesome5 } from '@expo/vector-icons';

import { isBrower } from '../../utils/functions';
import styles from '../../utils/styles';

export const Container = styled.View`
    width: 90%;
    height: 120px;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const Label = styled.Text`
    font-family: roboto_regular ;
    font-size: ${verticalScale(isBrower() ? 7 : 15)}px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: ${styles.inputLabelText};
    margin-left: 30px;
    margin-bottom: 5px;
`;

export const InputContainer = styled.View`
    display: flex;
    flex-direction: row;
    width: ${isBrower() ? 90 : 100}%;
    height: 60px;
    justify-content: center;
    align-items: center;
    border: 2px;
    border-color: ${styles.inputBorder};
    background-color: #FFFFFF;
`;

export const Input = styled.TextInput`
    width: 90%;
    height: 60px;
    align-self: center;
    font-family: roboto_regular ;
    font-size: ${verticalScale(isBrower() ? 7 : 15)}px;
    padding-left: 10px;
    color: ${styles.inputLabelText};
    &:focus {
        outline: none;
        border-color: transparent;
    }
`;

export const Error = styled.Text`
    font-family: roboto_regular ;
    font-size: ${verticalScale(isBrower() ? 5 : 13)}px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: ${styles.inputErrorText};
    margin-left: 35px;
`;

export const Icon = styled(FontAwesome5)`
    color: ${styles.inputIcon};
`;
