import styled from 'styled-components/native'

export const Container = styled.View`
    flex-direction: row;
    width: 100%;
    height: 60px;
    bottom: 0;
    justify-content: center;
    align-items: flex-end;
    background-color: transparent;
`;

export const Flag = styled.Image`
    width: 25px;
    height: 25px;
    resize-mode: contain;
    margin: 15px; 
`;
