import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import { isBrower } from '../../utils/functions';
import { postAuthRequest, postAuthMyUser } from '../../store/ducks/auth/actions';

import InputWithLabel from '../../components/InputWithLabel';
import LogoHeader from '../../components/LogoHeader';
import LanguageList from '../../components/LanguageList';
import BG from '../../components/BG';

import {
  Container,
  WebFormContainer,
  FormContainer,
  FormTitleContainer,
  FormTitle,
  FormInputsContainer,
  FormButtonContainer,
  FormSubmitButton,
  FormSubmitButtonText,
  ButtonLoader,
  PageLoader,
} from './styles';

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const error = useSelector((state: any) => state.auth.error);
  const access_token = useSelector((state: any) => state.auth.access_token);
  const language = useSelector((state: any) => state.language.login);

  useEffect(() => {
    if (access_token && access_token !== "") {

      dispatch(postAuthMyUser((res: any) => {
        if (res) {
          navigation.navigate("search");
        } else {
          setPageLoading(false);
          setLoading(false);
        }
      }
      ));

    } else {
      setPageLoading(false);
      setLoading(false);
    }

  }, [access_token, error]);

  function handleLogin() {
    setLoading(true);
    dispatch(postAuthRequest({ username: username, password: password }));
  }

  const getFormLayout = () => {

    return (
      <>
        <FormTitleContainer>
          <FormTitle>
            {language.title}
          </FormTitle>
        </FormTitleContainer>

        <FormInputsContainer>
          <InputWithLabel
            label={language.inputUserLabel}
            placeholder={language.inputUserPlaceholder}
            onChange={(e: any) => setUsername(e)}
            value={username}
            accessible={!loading}
          />
          <InputWithLabel
            label={language.inputPassLabel}
            placeholder={language.inputPassPlaceholder}
            onChange={(e: any) => setPassword(e)}
            value={password}
            isSecureText
            error={error}
            accessible={!loading}
          />
        </FormInputsContainer>

        <FormButtonContainer>
          <FormSubmitButton onPressIn={handleLogin}>
            {loading ?
              <ButtonLoader />
              :
              <FormSubmitButtonText>
                {language.button}
              </FormSubmitButtonText>
            }
          </FormSubmitButton>
        </FormButtonContainer>

      </>
    );
  }

  return (
    <>
      <BG />
      <Container>

        <LogoHeader />
        {
          pageLoading ?
            <PageLoader />
            :
            <>
              {
                isBrower() ?
                  <>
                    <WebFormContainer>
                      {getFormLayout()}
                    </WebFormContainer>
                    <LanguageList />
                  </>
                  :
                  <FormContainer>
                    {getFormLayout()}
                    <LanguageList />
                  </FormContainer>
              }
            </>
        }
      </Container>
    </>
  );
}

export default Home;