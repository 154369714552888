export default {
    formModalColor: '#F6F6F6',
    formTitle: '#767676',
    formButtonColor: '#EE3B5B',
    inputErrorText: '#EE3B5B',
    inputLabelText: '#0098B8',
    inputBorder: '#0098B8',
    inputIcon: '#0098B8',
    pageLoader: '#EE3B5B',
    searchIcon: '#EE3B5B',
    sideBarTitle: '#767676',
    statusCardTitle: '#EE3B5B',
    statusCardSubTitle: '#767676',
    statusCardLabel: '#767676',
}