import axios from 'axios';
import { store } from '../store/index';

const baseURL = "https://tramigocloud.com/api";

const auth = axios.create({
  baseURL: baseURL,
});

auth.interceptors.request.use(
  async config => {

    let access_token = store.getState().auth.access_token;

    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }


    return config;
  },
  error => Promise.reject(error)
);

export default auth;