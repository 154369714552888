import { AuthTypes, Authstate, FetchAction } from './types';
import { Reducer } from 'redux';

const initialState: Authstate = {
  access_token: "",
  error: "",
};

const reducer: Reducer<Authstate, FetchAction> = (state = initialState, action: FetchAction) => {

  switch (action.type) {
    case AuthTypes.POST_AUTH_SUCCESS:
      return { ...state, error: "", access_token: action.payload.access_token };

    case AuthTypes.POST_AUTH_LOGOUT_REQUEST:
      return { error: "",  access_token: "" };
    
    case AuthTypes.POST_AUTH_ERROR:
      return { ...state,  error: action.payload, access_token: "" };

    default:
      return state;
  }

};

export default reducer;