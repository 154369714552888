import React from 'react';
import { Alert } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import { LANGUAGE_ENUM } from '../../store/ducks/language/types';
import { postLanguage } from '../../store/ducks/language/actions';

import { Container, Flag } from './styles';

const LanguageList: React.FC = () => {

  const dispatch = useDispatch();

  function handlerLanguage(type: LANGUAGE_ENUM) {
    dispatch(postLanguage(type));
  }

  return (
    <Container>

      <TouchableWithoutFeedback onPressIn={() => handlerLanguage(LANGUAGE_ENUM.PT)}>
        <Flag source={require('../../../assets/icons/flag_portugal.png')} />
      </TouchableWithoutFeedback>

      <TouchableWithoutFeedback onPressIn={() => handlerLanguage(LANGUAGE_ENUM.ESP)}>
        <Flag source={require('../../../assets/icons/flag_espanha.png')} />
      </TouchableWithoutFeedback>

      <TouchableWithoutFeedback onPressIn={() => handlerLanguage(LANGUAGE_ENUM.EUA)}>
        <Flag source={require('../../../assets/icons/flag_eua.png')} />
      </TouchableWithoutFeedback>

      <TouchableWithoutFeedback onPressIn={() => handlerLanguage(LANGUAGE_ENUM.CN)}>
        <Flag source={require('../../../assets/icons/flag_china.png')} />
      </TouchableWithoutFeedback>

    </Container>
  );
}

export default LanguageList;