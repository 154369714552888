import styled from 'styled-components/native'
import { verticalScale } from 'react-native-size-matters'
import { isBrower } from '../../utils/functions';

const imageHeight = verticalScale(isBrower() ? 100 : 150);

export const Container = styled.View`
    width: 100%;
    height: ${imageHeight}px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
`;

export const Logo = styled.Image`
    width: 100%;
    height: ${imageHeight-20}px;
    resize-mode: contain;
`;