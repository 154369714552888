export enum LanguageTypes {
  POST_LANGUAGE = '@language/POST_LANGUAGE',
}

export enum LANGUAGE_ENUM{
  PT = 'pt',
  CN = 'cn',
  EUA = 'eua',
  ESP = 'esp',
}

export interface Language {
  login: {
    title: string,
    button: string,
    inputUserLabel: string,
    inputUserPlaceholder: string,
    inputPassLabel: string,
    inputPassPlaceholder: string,
  },
  search:{
      title: string,
      button: string,
      inputLabel: string,
      inputPlaceholder: string,
  },
  dashboard:{
      logoutButton: string,
      labelSearch: string,
      placeholderSearch: string,
      infoData: {
          title: string,
          subtitle: string,
          fieldOne: string,
          fieldTwo: string,
      },
      infoLocation: {
          title: string,
          subtitle: string,
          fieldOne: string,
          fieldTwo: string,
      },
      infoScript: {
          title: string,
          subtitle: string,
          fieldOne: string,
          fieldTwo: string,
      }
  }
}

export interface Languagestate {
  readonly login: {
    readonly title: string,
    readonly button: string,
    readonly inputUserLabel: string,
    readonly inputUserPlaceholder: string,
    readonly inputPassLabel: string,
    readonly inputPassPlaceholder: string,
  },
  readonly search:{
      readonly title: string,
      readonly button: string,
      readonly inputLabel: string,
      readonly inputPlaceholder: string,
  },
  readonly dashboard:{
      readonly logoutButton: string,
      readonly labelSearch: string,
      readonly placeholderSearch: string,
      readonly infoData: {
          readonly title: string,
          readonly subtitle: string,
          readonly fieldOne: string,
          readonly fieldTwo: string,
      },
      readonly infoLocation: {
          readonly title: string,
          readonly subtitle: string,
          readonly fieldOne: string,
          readonly fieldTwo: string,
      },
      readonly infoScript: {
          readonly title: string,
          readonly subtitle: string,
          readonly fieldOne: string,
          readonly fieldTwo: string,
      }
  }
}

export interface FetchAction {
  type: string;
  payload: any;
}