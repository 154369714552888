import { combineReducers } from 'redux';

import auth from './auth';
import device from './device';
import device_list from './device_list';
import device_location from './device_location';
import language from './language';

export default combineReducers({
  auth,
  device,
  device_list,
  device_location,
  language,
});