export enum DeviceTypes {
  POST_DEVICE_REQUEST = '@device/POST_DEVICE_REQUEST',
}

export interface Device {
  ID: string,
  IMEI: string,
  Group_ID: string,
  Name: string,
  DeviceType: string,
  TimeZone: string,
  DateTimeCreated: string,
  DateTimeLastModified: string,
}

export interface Devicestate {
  readonly ID: string,
  readonly IMEI: string,
  readonly Group_ID: string,
  readonly Name: string,
  readonly DeviceType: string,
  readonly TimeZone: string,
  readonly DateTimeCreated: string,
  readonly DateTimeLastModified: string,
}

export interface FetchAction {
  type: string;
  payload: any;
}