import AsyncStorage from '@react-native-async-storage/async-storage';
import { applyMiddleware, compose, createStore, Dispatch, MiddlewareAPI } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { Authstate } from './ducks/auth/types';

import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSaga';

export interface ApplicationState {
  auth: Authstate;
}

/*
 *--------------------------------------------------*
 * Persist config documentation
 * https://github.com/rt2zz/redux-persist/blob/master/src/types.js#L13-L27
 *--------------------------------------------------*
 */

const listPersist = {
  storage: AsyncStorage,
  key: 'rootdata',
  whitelist: ['auth', 'device', 'language']
};

const reducers = persistReducer(listPersist, rootReducer);

const appMiddleware = (_store: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
  next(action);
};

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, appMiddleware];
const enhancers = [applyMiddleware(...middlewares)];

export const store = createStore(reducers, compose(...enhancers));

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);