import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { getDistance } from 'geolib';
import * as Location from 'expo-location';
import moment from 'moment';
import MapView, { Marker } from 'react-native-maps';

import { postDeviceLocationRequest } from '../../store/ducks/device_location/actions';
import { postAuthLogoutRequest, postAuthMyUser } from '../../store/ducks/auth/actions';
import { isBrower } from '../../utils/functions';

import DashHeader from '../../components/DashHeader';

import {
  Container,
  PageLoader,
  ContainerMap,
  ContainerMapSideBar,
  SideBarTitle,
  ContainerStatusCard,
  StatusCardTitle,
  StatusCardSubTitle,
  StatusCardLabel,
  StatusCardRow,
  StatusCardColumn,
} from './styles';
import { Image, Text, View } from 'react-native';

const Dashboard: React.FC = () => {
  const config = require('../../config').default;

  const device = useSelector((state: any) => state.device);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [pageLoading, setPageLoading] = useState(true);

  const [locationData, setLocationData] = useState({});
  const [distanceData, setDistanceData] = useState({});

  const access_token = useSelector((state: any) => state.auth.access_token);

  const language = useSelector((state: any) => state.language.dashboard);

  useEffect(() => {
    dispatch(
      postDeviceLocationRequest({
        id: device.ID,
        callback: async (v: any) => {
          setLocationData(v);

          Location.getCurrentPositionAsync({})
            .then((location) => {
              if (v) {
                const distance = getDistance(
                  { latitude: parseFloat(v.Latitude), longitude: parseFloat(v.Longitude) },
                  { latitude: location.coords.latitude, longitude: location.coords.longitude },
                );
                const now = new Date();
                const time = moment(now).add((distance / 1000) * 10, "minutes").format('hh:mm:ss');
                setDistanceData({
                  time: time,
                  distance: parseInt(distance / 1000),
                })
              }
              setPageLoading(false);
            }).catch((r) => {
              alert(r.message)
              //navigation.navigate("search");
            });

        }
      })
    );
  }, [device]);

  useEffect(() => {
    setPageLoading(true);
    if (access_token && access_token !== "") {
      dispatch(postAuthMyUser((res: any) => {
        if (!res) {
          dispatch(postAuthLogoutRequest());
          navigation.navigate("login");
        }
        setPageLoading(false);
      }
      ));
    } else {
      dispatch(postAuthLogoutRequest());
      navigation.navigate("login");
    }
  }, [access_token]);

  function getMap(stichBrowser = false) {
    const latitude = locationData.Latitude;
    const longitude = locationData.Longitude;

    if (latitude && longitude) {
      const coords = {
        latitude: Number(latitude),
        longitude: Number(longitude),
      }
      if (stichBrowser) {
        return (
          <>
            <MapView style={{
              width: isBrower() ? "80%" : "100%",
              height: isBrower() ? "100%" : "50%"
            }}
              region={{
                ...coords,
                latitudeDelta: 0,
                longitudeDelta: 0
              }}
            >
              <MapView.Marker
                coordinate={coords}
              />
            </MapView>
          </>
        )
      } else {
        return (
          <>
            <iframe
              width={isBrower() ? "80%" : "100%"}
              height={isBrower() ? "100%" : "50%"}
              loading="lazy"
              src={"https://www.google.com/maps/embed/v1/place?key=" + config["googleToken"] + "&q=" + latitude + "," + longitude}
            />
          </>
        )
      }
    } else {
      return (<><PageLoader /></>)
    }
  }

  return (
    <Container>
      {
        pageLoading ?
          <PageLoader />
          :
          <>
            <DashHeader />
            <ContainerMap>
              {getMap(true)}
              <ContainerMapSideBar>
                <SideBarTitle>
                  {language.title}
                </SideBarTitle>

                <ContainerStatusCard>
                  <StatusCardTitle>
                    {language.infoData.title}
                  </StatusCardTitle>
                  <StatusCardColumn style={{ width: '100%' }}>
                    <StatusCardRow>
                      <StatusCardSubTitle>
                        {language.infoData.subtitle}
                      </StatusCardSubTitle>
                    </StatusCardRow>
                    <StatusCardRow>
                      <StatusCardColumn style={{ width: '50%' }}>
                        <StatusCardSubTitle>{language.infoData.fieldOne}</StatusCardSubTitle>
                        <StatusCardLabel>{device.ID}</StatusCardLabel>
                      </StatusCardColumn>
                      <StatusCardColumn style={{ width: '50%' }}>
                        <StatusCardSubTitle>{language.infoData.fieldTwo}</StatusCardSubTitle>
                        <StatusCardLabel>{device.Name}</StatusCardLabel>
                      </StatusCardColumn>
                    </StatusCardRow>
                  </StatusCardColumn>
                </ContainerStatusCard>

                <ContainerStatusCard>
                  <StatusCardTitle>
                    {language.infoLocation.title}
                  </StatusCardTitle>
                  <StatusCardColumn style={{ width: '100%' }}>
                    <StatusCardRow>
                      <StatusCardSubTitle>
                        {language.infoLocation.subtitle}
                      </StatusCardSubTitle>
                    </StatusCardRow>
                    <StatusCardRow>
                      <StatusCardColumn style={{ width: '50%' }}>
                        <StatusCardSubTitle>{language.infoLocation.fieldOne}</StatusCardSubTitle>
                        <StatusCardLabel>{locationData.Latitude || '----'}</StatusCardLabel>
                      </StatusCardColumn>
                      <StatusCardColumn style={{ width: '50%' }}>
                        <StatusCardSubTitle>{language.infoLocation.fieldTwo}</StatusCardSubTitle>
                        <StatusCardLabel>{locationData.Longitude || '----'}</StatusCardLabel>
                      </StatusCardColumn>
                    </StatusCardRow>
                  </StatusCardColumn>
                </ContainerStatusCard>

                <ContainerStatusCard>
                  <StatusCardTitle>
                    {language.infoScript.title}
                  </StatusCardTitle>
                  <StatusCardColumn style={{ width: '100%' }}>
                    <StatusCardRow>
                      <StatusCardSubTitle>
                        {language.infoScript.subtitle}
                      </StatusCardSubTitle>
                    </StatusCardRow>
                    <StatusCardRow>
                      <StatusCardColumn style={{ width: '50%' }}>
                        <StatusCardSubTitle>{language.infoScript.fieldOne}</StatusCardSubTitle>
                        <StatusCardLabel>{distanceData.time || '----'}</StatusCardLabel>
                      </StatusCardColumn>
                      <StatusCardColumn style={{ width: '50%' }}>
                        <StatusCardSubTitle>{language.infoScript.fieldTwo}</StatusCardSubTitle>
                        <StatusCardLabel>{distanceData.distance || '----'}km</StatusCardLabel>
                      </StatusCardColumn>
                    </StatusCardRow>
                  </StatusCardColumn>
                </ContainerStatusCard>
              </ContainerMapSideBar>
            </ContainerMap>
          </>
      }
    </Container>
  );
}

export default Dashboard;