import { LANGUAGE_ENUM, LanguageTypes, Languagestate, FetchAction } from './types';
import { Reducer } from 'redux';

const languages = require('../../../languages').default;

const initialState: Languagestate = {
  ...languages[LANGUAGE_ENUM.PT]
};

const reducer: Reducer<Languagestate, FetchAction> = (state = initialState, action: FetchAction) => {

  switch (action.type) {
    case LanguageTypes.POST_LANGUAGE:
      return {
        ...state,
        ...languages[action.payload]
      };
      
    default:
        return state;
  }

};

export default reducer;